// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.map-container {
  @extend %out-box;
  background: url('https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg');
  padding-bottom: (160rem / 100);


  .map-crumbs {
    @extend %inside-box;
    padding-top: (90rem / 100);
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #761F1E;
    margin-bottom: (28rem / 100);

    a {
      color: #273143;
      margin-right: 4px;
    }
  }
  .map-info{
    @extend %inside-box;
    padding-top: 0.28rem;
  }
  .map-tab-content {
    
  }

  .map-tab-show-more {
    // @extend %inside-box;
    // margin-top: (42rem / 100);

    div {
      margin: 0 auto;
      width: (149rem / 100);
      height: (50rem / 100);
      background: #761F1E;
      border-radius: (25rem / 100);
      text-align: center;
      line-height: (50rem / 100);
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }

  }
}
// 场馆预定
.maplist-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (330rem / 100);
    margin-right: (30rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage-index-item-bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: (20rem / 100);
    position: relative;
    margin-bottom: (30rem / 100);

    &-banner {
      width: 100%;
      height: (200rem / 100);
      overflow: hidden;
      margin-bottom: (14rem / 100);
      position: relative;

      &-total {
        width: (68rem / 100);
        height: (30rem / 100);
        background: #cd9e56;
        border-radius: 0 (20rem / 100) 0 0;
        position: absolute;
        left: 0;
        bottom: 0;
        text-align: center;
        line-height: (30rem / 100);
        font-size: (14rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
      }
    }

    &-bottom {
      width: 100%;
      // padding: 0 (10rem / 100) 0;

      &-title {
        width: (340rem / 100);
        height: (28rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        margin-bottom: (5rem / 100);
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
        display: flex;
              align-items: center;
        .name{
          margin-right: 0.1rem;
          max-width: 2.6rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .column{
          width: 0.02rem;
          height: 0.2rem;
          background-color: #273143;
          margin-right: 0.1rem;
        }
        .area{
          
        }
      }
      &-map{
        font-size: 0.16rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 0.22rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // margin-bottom: 0.03rem;
      }
      &-reserve{
        width: 0.96rem;
        height: 0.3rem;
        // background-image: url("https://image.bookgo.com.cn/%20webculture%2Fgeneral%2Fmap-list-reserve.png");
        // background-size: 100% 100%;
        background-color: #761F1E;
        border-radius: 0.25rem;
        font-size: 0.16rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 500;
        line-height: 0.3rem;
        color: #FAFDFE;
        text-align: center;
        // position: absolute;
        // top: 0;
        // right: 0;
        position: relative;
        float: right;
        cursor: pointer;
      }
    }
  }

  .maplist-li-selected {
    width: 100%;
    height: (37rem / 100);
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    .maplist-li-selected {
      display: block;
    }
  }
}
.tab-secondNav-choose-item {
  font-size: 0.17rem;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item {
  display: flex;
}
.tab-secondNav-choose-item li {
  margin-left: 10px;
}
.tab-secondNav-choose-item li {
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child) {
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav {
  color: #761f1e;
  position: relative;
}

.tabActivesecondNavSpan {
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan {
  width: 80%;
  background: #991c10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch {
  margin-top: 0.33rem;
}
.news-container {
  padding-top: 0.38rem;
}
// 顶部
.culture-switch-type {
  display: flex;
  margin-bottom: (12rem / 100);
  margin-top: 0.2rem;

  &-title {
    font-size: (16rem / 100);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #761f1e;
    line-height: (32rem / 100);
    margin-right: (10rem / 100);
  }

  &-content {
    display: flex;
    flex-wrap: wrap;

    &-li {
      min-width: (100rem / 100);
      height: (32rem / 100);
      margin: 0 (30rem / 100) (12rem / 100) 0;
      background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: (32rem / 100);
      font-size: (16rem / 100);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #273143;
      cursor: pointer;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }
    
    .imgType {
      background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
      no-repeat;
      background-size: 100% 100%;

    }
    .imgType-active {
      background: url("https://image.bookgo.com.cn/webculture/jm/activity_type_li.png")
        no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      font-weight: 500;
    }
    &-li-active {
      background: url("https://image.bookgo.com.cn/webculture/jm/activity_type_li.png")
        no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      font-weight: 500;
    }
    
  }
}